import React from 'react';
import FAQItem from '../../../components/FAQ-item';
import Layout from '../../../components/layout/layout';
import {PageHero} from '../../../components/page-hero';
import Section from '../../../components/section';
import WhoisDomainLookupWrapper from '../../../components/tools-components/whois-domain-lookup';

const WhoisDomainLookup = () => (
  <Layout>
    <PageHero
      title="WHOIS Domain Lookup"
      titleSmall
      subtitle="Discover domain expiry, ownership, contact, registrar and nameserver information for any domain or IP powered by Hexomatic"
      className="toolPage"
      id='seo_home_area_tools'
    />
    <Section className="m-auto w-100">
      <WhoisDomainLookupWrapper />
    </Section>
    <Section>
      <div className="col-12 d-block mb_20 m-auto" style={{maxWidth: '800px', width: '100%'}}>
        <h4 className="mb-4 text-center">WHOIS Domain Lookup FAQ</h4>
        <FAQItem
          title="What is a WHOIS domain lookup?"
          text={
            <>
              <p>
                A WHOIS domain lookup allows tracking the ownership of a given domain name. All domain name registries
                keep a record of every domain name purchased through them, including information about the owner, the
                purchase date, and more.
              </p>
              <p>Simply said, a domain name is the website’s address by which it can be found by users.</p>
            </>
          }
        />
        <FAQItem
          title="What does the WHOIS domain database contain?"
          text={
            <p>
              A listing of registered domains is known as WHOIS domain database. It can be used for a number of legal
              purposes. For example, network administrators use the WHOIS lookup to detect and fix problems. WHOIS
              information can also be used for checking domain name availability, keeping domain name registrants
              accountable, etc.
            </p>
          }
        />
        <FAQItem
          title="How do you find WHOIS information?"
          text={
            <p>
              Our WHOIS domain lookup tool allows finding WHOIS information, which contains details such as the
              registration, expiration date, ownership, contact information as well as the name server or registrar used
              when the domain was purchased. All you need to do is to insert the targeted website URL, and our tool will
              return the domain age, the date of creation, update, and expiration, as well as WHOIS Raw data and DNS
              records.
            </p>
          }
        />
        <FAQItem
          title="What is WHOIS lookup data used for?
          "
          text={
            <>
              <p>
                WHOIS enabled the democratization of the Internet. Anyone from businesses to individuals can access
                WHOIS database of websites and find out the domain information of any website.
              </p>
              <p>
                WHOIS lookup data can mainly be used for competitive analysis, finding contact details of a company, to
                understand expiration dates for a given domain and more.
              </p>
            </>
          }
        />
        <FAQItem
          title="What is WHOIS lookup IP?"
          text={
            <>
              <p>
                An IP is a unique address of an Internet server. It enables your computer to connect to a specific
                server on the internet. For each IP number and the organization it was assigned, a record is maintained
                by governing bodies.
              </p>
              <p>A WHOIS IP lookup allows tracking the same details for a domain.</p>
            </>
          }
        />
        <FAQItem
          title="How do you update your WHOIS information?"
          text={
            <p>
              For updating your WHOIS information, you should contact your WHOIS registrar to find out the available
              options.
            </p>
          }
        />
        <FAQItem
          title="How do you hide your info on WHOIS?"
          text={
            <p>
              To hide your WHOIS information, you need to receive private registration from your domain registrar. Next,
              you need to check details at a WHOIS service to learn whether your information is private or not.
            </p>
          }
        />
        <FAQItem
          title="What is the difference between a domain name and a website?"
          text={
            <p>
              A website is what people see and interact with while a domain is a unique name of a website, an
              easy-to-remember way to access a website.
            </p>
          }
        />
      </div>
    </Section>
  </Layout>
);

export default WhoisDomainLookup;
