import {navigate} from 'gatsby-link';
import React from 'react';
import Button from '../../button';
import picture from '../../../images/tools/whois/one.png';
import picture1 from '../../../images/tools/whois/two.png';
import hexomaticGif from '../../../images/Hexomatic_Translation.gif';

const Info = () => (
  <div className="whois-info row mt-0 mx-0 mb-3">
    <div className="col-12 col-md-6 m-auto">
    <h4 className="mb-4">How do WHOIS lookups work?</h4>
      <div className="mb-2">
        WHOIS lookups allow you to fetch live information from the WHOIS global database, which contains details such as
        the registration, expiration date, ownership, contact information as well as the namesever or registrar used
        when the domain was purchased.
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={picture1} alt="WHOIS Domain Lookup" />
    </div>

    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={picture} alt="text to speech" />
    </div>
    <div className="col-12 col-md-6 mb-5">
      <h4 className="mb-4">How can you use WHOIS domain lookups in your business?</h4>
      <div className="mb-2">There are many use cases where you can benefit from WHOIS data including:</div>
      <ul className="p-0">
        <li>Competitive research</li>
        <li>Discovering more information about competitors</li>
        <li>Finding contact details for an organisation</li>
        <li>Understanding when a domain might expire</li>
        <li>Discovering recency of a domain name or business</li>
        <li>Assist during investigations</li>
        <li>Get more checks</li>
      </ul>
    </div>
    <div className="col-12 col-md-6 mb-5 p-2">
      <h4 className="mb-4">Automate time consuming WHOIS tasks with Hexomatic</h4>
      <div className="mb-2">
        Hexomatic is a unique work automation platform that enables you to tap into 100+ ready made automations to create
        your perfect workflow.
      </div>
      <div className="mb-4">
        Perform WHOIS checks at scale and combine with email, social media or phone number scraping via a simple point
        and click interface.
      </div>
      <div>
        <Button onClick={() => navigate('/tools/')}>Click here to find out more</Button>
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={hexomaticGif} alt="WHOIS Domain Lookup" />
    </div>
  </div>
);

export default Info;
